<template>
	<div class="search">
		<label>
			<span class="label">Suche</span>
			<span class="search-field">
				<BaseIcon :src="'ui/search.svg'" />
				<input
					ref="search"
					v-model="search"
					type="search"
					:placeholder="'z.B. Ankylosaurier'"
				>
			</span>
		</label>
	</div>
</template>

<script>
import {EventBus} from '../event-bus';


export default {
	name: 'AppSearch',
	data() {
		return {
			keylog: [],
			keypressTimer: 0,
			keypressEvent: false
		};
	},
	computed: {
		search: {
			get() {
				return this.$store.state.search;
			},
			set(value) {
				this.$store.commit('setSearch', value.trim());
				this.$store.dispatch('addFilter', {
					type: 'search',
					value: value.trim()
				});
			}
		}
	},
	mounted() {
		EventBus.$on('AppMenu:onClose', this.addKeypressEvent);
		EventBus.$on('AppMenu:onOpen', this._onOpen);
		this.addKeypressEvent();
	},
	beforeDestroy() {
		EventBus.$off('AppMenu:onClose', this.addKeypressEvent);
		EventBus.$off('AppMenu:onOpen', this._onOpen);
		this.removeKeypressEvent();
	},
	methods: {
		addKeypressEvent() {
			if (!this.keypressEvent) {
				this.keypressEvent = true;
				document.addEventListener('keydown', this._onKeypress);
			}
		},
		removeKeypressEvent() {
			if (this.keypressEvent) {
				document.removeEventListener('keydown', this._onKeypress);
				this.keypressEvent = false;
			}
		},
		_onKeypress(e) {
			const key = e.key;
			window.clearTimeout(this.keypressTimer);
			this.keypressTimer = window.setTimeout(() => {
				this.keylog = [];
			}, 1000);

			if (key.length === 1 && key.match(/^[a-zA-ZäöüÄÖÜ-]/)) {
				this.keylog.push(key);

				if (this.keylog.length === 3) {
					EventBus.$emit('AppMenu:open');
					this.search = this.keylog.join('').slice(0, -1);
					this.$refs.search.focus();
				}
			}
		},
		_onOpen() {
			EventBus.$emit('Modal:close');
			this.removeKeypressEvent();
		}
	}
};
</script>

<style lang="scss">
	.search {
		&-field {
			display: block;
			position: relative;
		}

		svg {
			transform: translateY(-50%);
			position: absolute;
			top: 50%;
			left: 1rem;
			width: 1.5rem;
			height: 1.5rem;
			fill: $color-text;
		}

		input {
			@include button-reset();
			transition: 0.3s ease-out;
			transition-property: background-color, border-color;
			padding: 0 1.5rem 0 3rem;
			width: 100%;
			min-height: 2.5rem;
			background-color: $color-light;
			border: 1px solid $color-light;
			border-radius: 2rem;
			color: $color-text;
			font-size: 0.875rem;

			&:hover,
			&:focus {
				background-color: $color-border;
				border-color: $color-border;
			}
		}

		.label {
			display: block;
			margin-bottom: 0.5rem;
			font-weight: bold;
		}
	}
</style>
